(function($){

  var san = {

    init: function(){

      san.hamNavToggle();
      san.locationSet();
      san.zipLookup();
      san.triggers();      
      san.modernForms();
      san.lightbox();

      if($('#anythingfilter').length){
        san.anythingfilter();
      }
      if($('.action-button').length){
        san.formSubmit();
      }
      if($('.product-images').length){
        san.simpleGallerySetup();
        san.simpleGallery();
      }
      if($('.product-filter-current').length){
        // san.productFilterBuild();
        san.productFilterChange();
      }
      if($('.slider-set').length){
        san.simpleSlider();
      }
      if($('.map-visual-trigger').length){
        san.highlightHomeMap();
      }
      if($('.prod-detail-toggle').length){
        san.productDetailsToggle();
      }
    },
    anythingfilter: function() {
      var filterables = $('.filterables');
      $('input#anythingfilter').keyup(function(){
        var curr = $(this).val().toLowerCase();
        $('.filterable', filterables).each(function(){
          var thistext = $(this).text().toLowerCase();
          if(thistext.includes(curr)){
            $(this).show();
          } else {
            $(this).hide();
          }
        });
      });
    },
    hamNavToggle: function(){

      $('.nav-ham-trigger').on('click', function(){
        $('body').addClass('nav-open');
      });

      $('.nav-header-shade').on('click', function(){
        $('body').removeClass('nav-open');
      });

    },
    highlightHomeMap: function(){
      var loc = Cookies.get('sansomofficeshort');
      $('.map-visual-trigger[data-target=' +loc+ ']').next().next('.map-area-content').addClass('current-loc');

      $('.map-visual-trigger').hover(function(){
          $('body').addClass('map-interacting');
        }, function(){
          $('body').removeClass('map-interacting');
      });
    },
    simpleSlider: function(){

      var item_count = $('.slider-set:first .slider').length;

      setInterval(function(){
        var arr = [];
        while(arr.length < 5){
          var r = Math.floor(Math.random() * item_count);
          if(arr.indexOf(r) === -1) arr.push(r);
        }
        $('.slider-set:nth-of-type(1) .slider:eq(' +(arr[0])+ ')').addClass('active').siblings().removeClass('active');
        $('.slider-set:nth-of-type(2) .slider:eq(' +(arr[1])+ ')').addClass('active').siblings().removeClass('active');
        $('.slider-set:nth-of-type(3) .slider:eq(' +(arr[2])+ ')').addClass('active').siblings().removeClass('active');
        $('.slider-set:nth-of-type(4) .slider:eq(' +(arr[3])+ ')').addClass('active').siblings().removeClass('active');
        $('.slider-set:nth-of-type(5) .slider:eq(' +(arr[4])+ ')').addClass('active').siblings().removeClass('active');
      },2000);
    },
    simpleGallerySetup: function(){

      $('.product-images').each(function(){
        var firstimg = $('.product-entry-image:first',this);
        var getsrc = $('img',firstimg).data('url');
        $('img',firstimg).attr('src', getsrc);
        firstimg.addClass('active');

        // count number of images
        // render same number of dots
        var imgcount = $('.product-entry-image',this).length;
        for (let i = 0; i < imgcount; i++) {
          $('.product-images-dots',this).append('<span class="product-images-dot"></span>');
        }

        // activate first dot
        $('.product-images-dot:first',this).addClass('active');

        // add arrows
        if (imgcount > 1) {
          $('.product-images-dots',this).prepend('<span class="product-images-dot-prev"></span>').append('<span class="product-images-dot-next"></span>');
        }
      });

    },
    simpleGallery: function(){

      $('.product-images-dots').on('click', '.product-images-dot', function(){
        var thisdotpos = $(this).index();
          // console.log(thisdotpos);
        var thisgroup = $(this).closest('.product-images');
        var thisimage = $('.product-entry-image:eq(' +(thisdotpos - 1)+ ')',thisgroup);
        // load the image
        var getsrc = $('img',thisimage).data('url');
        $('img',thisimage).attr('src', getsrc);
        // indicate active
        $(this).addClass('active').siblings().removeClass('active');
        thisimage.addClass('active').siblings().removeClass('active');
      });

      $('.product-images-dots').on('click', '.product-images-dot-next', function(){
        var thisset = $(this).closest('.product-images-dots');
        var pos = $('.product-images-dot.active',thisset).index();
        var len = $('span', thisset).length;
        // console.log(len);
        // console.log(pos);

        if(pos < (len - 2)){
          var thisdot = $('.product-images-dot.active',thisset).next();
          var thisdotpos = pos + 1;
          var thisgroup = $(this).closest('.product-images');
          var thisimage = $('.product-entry-image:eq(' +(thisdotpos - 1)+ ')',thisgroup);
          // load the image
          var getsrc = $('img',thisimage).data('url');
          $('img',thisimage).attr('src', getsrc);
          // indicate active
          thisdot.addClass('active').siblings().removeClass('active');
          thisimage.addClass('active').siblings().removeClass('active');
        }
      });

      $('.product-images-dots').on('click', '.product-images-dot-prev', function(){
        var thisset = $(this).closest('.product-images-dots');
        var pos = $('.product-images-dot.active',thisset).index();
        var len = $('span', thisset).length;
        console.log(len);
        console.log(pos);

        if(pos > (1)){
          var thisdot = $('.product-images-dot.active',thisset).prev();
          var thisdotpos = pos - 1;
          var thisgroup = $(this).closest('.product-images');
          var thisimage = $('.product-entry-image:eq(' +(thisdotpos - 1)+ ')',thisgroup);
          // load the image
          var getsrc = $('img',thisimage).data('url');
          $('img',thisimage).attr('src', getsrc);
          // indicate active
          thisdot.addClass('active').siblings().removeClass('active');
          thisimage.addClass('active').siblings().removeClass('active');
        }
      });


      // listen for dot clicks
      // get index position; make active, siblings inactive

    },
    productFilterShow: function(){
      // show current value if Product filter being used
      var filter = $('select.product-filter').val();
      $('.product-filter-current').html(filter);      
      // reset category filter to All
      $('select.product-cat').val('all');
    },
    categoryFilterShow: function(){
      // show current value if Category filter being used
      var filter = $('select.product-cat').val();
      $('.product-filter-current').html(filter);
      // reset product filter to All
      $('select.product-filter').val('all');
    },
    // productFilterBuild: function(){

    //   // reset category & product filters
    //   $('select.product-filter').html('<option value="All">All</option>');
    //   $('select.product-cat').html('<option value="All">All</option>');

    //   // add other options to both filters
    //   var manus = [];
    //   var cats = [];
    //   $('.product-entry').each(function(){
    //     var thismanu = $(this).data('manu');
    //     manus.push(thismanu);

    //     var thiscat = $(this).data('cat');
    //     cats.push(thiscat);
    //   });

    //   var manusunique = manus.filter(onlyUnique);
    //   var catsunique = cats.filter(onlyUnique);
    //     // console.log(manusunique);
    //   // iterate uniques and build filter
    //   for(let i = 0; i < manusunique.length; i++){
    //     // console.log(manusunique[i]);
    //     var addoption = '<option value="' +manusunique[i]+ '">' +manusunique[i]+ '</option>';
    //     $('select.product-filter').append(addoption);
    //   }

    //   for(let i = 0; i < catsunique.length; i++){
    //     var addoption = '<option value="' +catsunique[i]+ '">' +catsunique[i]+ '</option>';
    //     $('select.product-cat').append(addoption);
    //   }

    //   san.productFilterShow();

    //   // helper function
    //   function onlyUnique(value, index, self) {
    //     return self.indexOf(value) === index;
    //   }

    // },
    productDetailsToggle: function(){
      // initial show
      $('.prod-detail').addClass('hide');
      $('.prod-detail:first').removeClass('hide');

      // listen for change
      $('.prod-detail-toggle').on('click', function(){
        $(this).closest('.prod-detail').toggleClass('hide');
      });

    },
    productFilterChange: function(){

      $('select.product-filter').on('change', function(){
        var newfilter = $(this).val();
        if(newfilter == 'all') {
          $('.product-entry').show();
        } else {
          $('.product-entry').each(function(){
            if($(this).data('manu') == newfilter) {
              $(this).show();
            } else {
              $(this).hide();
            }
          });
        }
        san.productFilterShow();
      });

      $('select.product-cat').on('change', function(){
        var newfilter = $(this).val();
        if(newfilter == 'all') {
          $('.product-entry').show();
        } else {
          $('.product-entry').each(function(){
            var thisselect = $(this).data('cat')
            if(thisselect.includes(newfilter)) {
              $(this).show();
            } else {
              $(this).hide();
            }
          });
        }
        san.categoryFilterShow();
      });

      // listen for change
      // update visible listings

        // hide :not same brand
      // update show title
    },
    locationCheck: function(){

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition, showError);
      } else {
        // unsupported
      }

      function showPosition(position) {
        $.post('/checkloc?lat='+position.coords.latitude +'&lng='+position.coords.longitude,function(data){ });
      }

      function showError(error) {
        switch(error.code) {
          case error.PERMISSION_DENIED:
            // "User denied the request for Geolocation."
            defaultAsMobile();
            break;
          case error.POSITION_UNAVAILABLE:
            // "Location information is unavailable."
            defaultAsMobile();
            break;
          case error.TIMEOUT:
            // "The request to get user location timed out."
            defaultAsMobile();
            break;
          case error.UNKNOWN_ERROR:
            // "An unknown error occurred."
            defaultAsMobile();
            break;
        }
      }

      function defaultAsMobile(){
        // move to error handling?
        var location_html = 'Mobile, AL (251) 631-3766';
        $('#location-current').addClass('not-set').html(location_html);
        Cookies.set('sansomofficeshort', 'mobile');
        Cookies.set('sansomofficelegible', location_html);        
      }

    },
    zipLookup: function(){

      $('.location-change').on('click', function(){
        $('body').toggleClass('location-open');
      });

      $('.location-input-shade').on('click', function(){
        $('body').removeClass('location-open');
      });

      $('.zip-submit').on('click', function(){
        var thiszip = $('input#local_zip').val();
        $.post('/checkzip?zip='+thiszip ,function(data){ });
      });

    },
    locationSet: function(){

      var location_saved = Cookies.get('sansomofficelegible');

      if(location_saved != undefined){
        $('#location-current').removeClass('not-set').html(location_saved);
      } else {
        san.locationCheck();
      }

      // hard click on Mobile, AL
      $('#manage-location-wrap').on('click', '.zip-hard-set-mobile', function(){
        var location_html = 'Mobile, AL (251) 631-3766';
        $('#location-current').removeClass('not-set').html(location_html);
        Cookies.set('sansomofficeshort', 'mobile');
        Cookies.set('sansomofficelegible', location_html);
        $('body').removeClass('location-open');
        $('.zip-message').removeClass('active').html('');

      });

    
    },
    setNewLocation: function(){
      var short = $('#set-matched-location').data('sansomofficename');
      var legible = $('#set-matched-location').data('sansomofficelegible');
      Cookies.set('sansomofficeshort', short);
      Cookies.set('sansomofficelegible', legible);
      $('#location-current').removeClass('not-set').html(legible);
      $('body').removeClass('location-open');
      $('.zip-message').removeClass('active').html('');

        console.log('set:' + short);
    },
    formSubmit: function(){

      // can break out action to separate if/else
      $('.action-button[data-action="send-message"]').on('click', function(){
        // validate
        var thisform = $(this).closest('.message-form');
        $('.p-fieldgroup',thisform).each(function(){
          if($(this).hasClass('required')){
            // check if input or textarea
            if($('input',this).length){
              var thisvalue = $('input',this).val();
            } else if($('textarea',this).length){
              var thisvalue = $('textarea',this).val();
            }
            // check if empty
            if(thisvalue != ''){
              $(this).removeClass('error');
            } else {
              $(this).addClass('error');
            }
          }
        });
        // show error or submit
        if($('.p-fieldgroup.required.error').length){
          thisform.append('<div class="error-message"><em>Please complete all required fields.</em></div>');
        } else {

          var thisform = $('form#message-form');
          $.ajax({
            url: '/sendmessage',
            type: 'POST',
            data: thisform.serialize()
          });        
        }
        
      });

    },
    triggers: function(){
      $('#set-matched-location').on('click', function(){
        san.setNewLocation();
      });

      $(document).on('click', '.trigger', function(){
        var model = $(this).data('model');
        var action = $(this).data('action');
        var send = $(this).data('datum');
        $.post('/' +model+ '-' +action+ '?send='+send ,function(data){ });
      });

    },
    modernForms: function(){
      $(document).on('submit', 'form.modern', function(e){
        e.preventDefault();
        var thisform = $(this);

        if(thisform.hasClass('working')){
          // stay in limbo
        } else {
          thisform.addClass('working');

          var form_data = new FormData($(thisform)[0]);
          var endpoint = $(thisform).attr('action');

          $.ajax({
            url: endpoint,
            type: 'POST',
            data: form_data,
            processData: false,
            contentType: false
          });
        }

      });
    },
    lightbox: function(){
      $(document).on('click', '.close-lightbox', function(){
        $('.lightbox').remove();
      });
    }

  };


  $(window).on('load', function(){

    san.init();

  });

  $(window).on('resize', function() {

  });


})(window.jQuery);